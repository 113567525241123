import React, { useRef, useState, useMemo } from "react";
import { Player, Audio } from "@vime/react";
import Icon from "../Icon";

const AudioPlayer = React.memo(({ url, showControls }) => {
  const player = useRef();
  const [paused, setPaused] = useState(true);

  const icon = useMemo(() => (paused ? "play" : "pause"), [paused]);

  if (url) {
    return (
      <>
        <div className="h-0 w-0 overflow-hidden">
          <Player
            ref={player}
            controls={false}
            playsinline
            onVmPlaybackEnded={() => setPaused(true)}
          >
            <Audio>
              <source src={url} data-src={url} type="audio/mpeg" />
              {/* <source> and <track> elements are placed here. */}
            </Audio>
          </Player>
        </div>
        <button
          type="button"
          className="group flex shrink-0 rounded-full border-2 border-viridian p-3 transition duration-300 hover:scale-105"
          onClick={() => {
            if (player.current) {
              if (player.current.playing) {
                player.current.pause();
                setPaused(true);
              } else {
                player.current.play();
                setPaused(false);
              }
            }
          }}
        >
          <Icon
            name={icon}
            className="h-10 w-10 text-viridian transition duration-300 group-hover:scale-110"
            fitHeight
          />
        </button>
      </>
    );
  }
  return null;
});

AudioPlayer.defaultProps = {};

export default AudioPlayer;
